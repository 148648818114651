import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import * as Styled from './styles';
import type { Props } from './types';

function IconCircle({
  icon,
  bgColor,
  diameter,
  padding,
  className,
}: Props): JSX.Element {
  const { customTheme } = useContext(ThemeContext);

  return (
    <Styled.Circle
      className={className}
      $diameter={diameter || 'fit-content'}
      $bgColor={bgColor || customTheme.colors.arctic400}
      $padding={padding || customTheme.spacing.base}
    >
      {icon}
    </Styled.Circle>
  );
}

export default IconCircle;
