import * as Styled from './styles';

function LogoutView(): JSX.Element {
  return (
    <Styled.Container>
      <Styled.Circle icon={<Styled.Icon />} />
      <Styled.Title>You&apos;re logged out</Styled.Title>
      <Styled.Text $weight="regular">
        To log back in, please access with the email link.
      </Styled.Text>
    </Styled.Container>
  );
}

export default LogoutView;
