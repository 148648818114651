import styled from 'styled-components';

export const Circle = styled.div<{
  $bgColor: string;
  $diameter: string;
  $padding: string;
}>`
  display: flex;
  border-radius: 50%;
  width: ${({ $diameter }) => $diameter};
  height: ${({ $diameter }) => $diameter};
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$bgColor};
  padding: ${({ $padding }) => $padding};

  & svg {
    vertical-align: top;
  }
`;
